.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;      
    width: 5vh;      
  }
  
  .loader {
    border: 1vh solid #fcec52;
    border-top: 1vh solid black;
    border-radius: 100%;
    width:3vh;
    height: 3vh;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  