
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  font-family: 'Lato', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
code {
  font-family: 'Lato', sans-serif;
}
::-webkit-scrollbar {
  width: 0;
}
.container{
  display: flex;  
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  height: auto; /* Set height to auto */
  max-height: 100vh; /* Ensure it doesn't exceed viewport height */
  max-width: 100%;
  border: 1px solid red;
}